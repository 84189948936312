/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import nightOwlLight from "prism-react-renderer/themes/nightOwlLight"
import nightOwl from "prism-react-renderer/themes/nightOwl"
import theme from "../gatsby-plugin-theme-ui/index"
import { motion, AnimatePresence } from "framer-motion"

import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live"
import CodeBlockTitle from "./code-block-title"

const InteractiveCodeBlock = ({
  className,
  code,
  noInline,
  disabled,
  title,
}) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  // if there are no matches, matches will be null
  const matches = className.match(/language-(?<lang>.*)/) // use named capture group called "lang" (the part in the < >)
  const lang =
    matches && matches.groups && matches.groups.lang ? matches.groups.lang : ""

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row", "row"],
        marginBottom: "1.45rem",
        height: "100%",
        maxHeight: ["800px", "800px", "400px", "400px"],
        gridColumn: ["1", "1", "2 / -1", "2 / -1"],
        width: ["100%", "100%", "calc(100% + 250px)", "calc(100% + 350px)"], // width of parent container + width of TableOfContents
        boxShadow: theme => `0px 0px 35px 35px ${theme.colors.background}`,
      }}
    >
      <LiveProvider
        code={code}
        noInline={noInline}
        theme={isDark ? nightOwl : nightOwlLight} // using the same Prism theme as the `CodeBlock` component
        disabled={disabled}
        scope={{motion, AnimatePresence}}
      >
        <div
          sx={{
            width: ["100%", "100%", "50%", "50%"],
            height: "100%",
            minHeight: "400px",
            maxHeight: "400px",
            overflow: "scroll",
            borderStyle: "solid",
            borderSize: "1px",
            borderColor: theme => theme.colors.highlight,
            borderRight: "none"
          }}
        >
          {lang ? <CodeBlockTitle lang={lang} title={title} /> : null}
          <LiveEditor
            style={{ paddingTop: "0", fontSize: "min(16px, 1.25rem)" }}
          />
        </div>

        <LiveError />
        <div
          sx={{
            width: ["100%", "100%", "50%", "50%"],
            height: "100%",
            minHeight: "400px",
            maxHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.98",
            borderStyle: "solid",
            borderWidth: "4px 4px 4px 0",
            borderColor: isDark
              ? theme.colors.modes.dark.highlight
              : theme.colors.highlight,
            backgroundColor: isDark
              ? theme.colors.modes.dark.highlight
              : theme.colors.highlight,
          }}
        >
          <LivePreview
            style={{
              width: "100%",
              height: "100%",
              minHeight: "400px"
            }}
          />
        </div>
      </LiveProvider>
    </div>
  )
}

export default InteractiveCodeBlock
