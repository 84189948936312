/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import {TITLE_HEIGHT} from './code-block'

const CodeBlockTitle = ({ lang, title, hideLang }) => {
  return (
    <div
      sx={{
        variant: `styles.CodeBlock.title`,
        display: "flex",
        justifyContent: title ? "space-between" : "flex-end",
        alignItems: "center",
        bg: "highlight",
        padding: (hideLang && !title) && 0,
        height: !(hideLang && !title) && TITLE_HEIGHT,
      }}
    >
      {title ? <span>{title}</span> : null}
      {!hideLang ? <span>{lang.toUpperCase()}</span> : null}
    </div>
  )
}

CodeBlockTitle.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  hideLang: PropTypes.bool
}

export default CodeBlockTitle
