/** @jsx jsx */
import { jsx, Styled, useColorMode } from "theme-ui"
import Highlight, { defaultProps } from "prism-react-renderer"
import { useContext, useMemo } from "react"
import PropTypes from "prop-types"
import nightOwl from "prism-react-renderer/themes/nightOwl"
import CodeBlockTitle from "./code-block-title"
import { calculateLinesToHighlight } from "../utils/calculateCodeBlockLinesToHighlight"
import { ClipboardContext } from "../utils"
import { FaClipboard, FaClipboardCheck } from "react-icons/fa"

export const TITLE_HEIGHT = "32px"

const CodeBlock = ({
  children,
  className: outerClassName,
  title,
  hl,
  showLines,
  ...props
}) => {
  const { clipboard, copyToClipboard } = useContext(ClipboardContext)
  const isCopied = useMemo(() => clipboard?.value === children.trim(), [clipboard, children])
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const { hideLang } = props.props // not sure why it only works like this when showLines works fine

  // MDX will pass the language as className
  // className also includes className(s) theme-ui injected
  const [language] = outerClassName.replace(/language-/, ``).split(` `)
  if (typeof children !== `string`) {
    // MDX will pass in the code string as children
    return null
  }
  const shouldHighlightLine = calculateLinesToHighlight(hl)

  return (
    <div sx={{ position: "relative" }}>
      <CodeBlockTitle lang={language} title={title} hideLang={hideLang} />
      <button
        onClick={() => copyToClipboard(children.trim())}
        sx={{
          zIndex: 1,
          position: "absolute",
          top: `calc(${!(hideLang && !title) ? TITLE_HEIGHT : "4px"} + 8px)`, // 4px is border width
          right: "8px",
          display: "flex",
          placeItems: "center",
          padding: "6px 8px",
          bg: "highlight",
          color: 'text',
          borderRadius: "4px",
          borderWidth: 0,
          fontWeight: 500,
          fontSize: "14px",
          cursor: "pointer",
          opacity: 0.8,
          transition: "color, background-color .25s linear",

          ":hover": {
            bg: isDark ? "text" : "muted",
            color: "background",
          },
        }}
      >
        {isCopied ? <FaClipboardCheck sx={{ marginRight: '4px' }}/> : <FaClipboard sx={{ marginRight: '4px' }}/>}
        {isCopied ? "Copied!" : "Copy"}
      </button>
      <div sx={{ variant: `styles.CodeBlock`, position: "relative" }}>
        <Highlight
          {...defaultProps}
          {...props}
          code={children.trim()}
          language={language}
          theme={isDark ? nightOwl : undefined}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <Styled.pre
              className={`${outerClassName} ${className}`}
              style={style}
            >
              {tokens.map((line, index) => {
                const lineProps = getLineProps({ line, key: index })
                return (
                  <div
                    key={index}
                    {...lineProps}
                    sx={
                      shouldHighlightLine(index)
                        ? { variant: `styles.CodeBlock.highlightLine` }
                        : undefined
                    }
                  >
                    <div key={`${line}-${index}`} {...lineProps}>
                      {showLines ? (
                        <span
                          sx={{
                            variant: `styles.CodeBlock.lineNumber`,
                            left: shouldHighlightLine(index)
                              ? `calc(-1.45em + 15px)`
                              : `calc(-1.45em + 19px)`,
                          }}
                        >
                          {index + 1}
                        </span>
                      ) : null}
                      {line.map((token, key) => (
                        <span
                          key={key}
                          {...getTokenProps({ token, key })}
                          // https://github.com/system-ui/theme-ui/pull/721
                          sx={
                            token.empty
                              ? { display: `inline-block` }
                              : undefined
                          }
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </Styled.pre>
          )}
        </Highlight>
      </div>
    </div>
  )
}

CodeBlock.propTypes = {
  children: PropTypes.node.isRequired,
  outerClassName: PropTypes.string,
  title: PropTypes.string,
  hl: PropTypes.string,
  showLines: PropTypes.bool,
  props: PropTypes.object,
}

export default CodeBlock
