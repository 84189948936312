/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { FaLink } from "react-icons/fa"
import InteractiveCodeBlock from "./components/interactive-code-block"
import CodeBlock from "./components/code-block"
import AnimatedLink from "./components/animated-link"
import Emoji from "./components/emoji"
import {useLocation} from '@reach/router';
import { useCopyToClipboard } from "react-use"

function CustomLink({href, ...props}) {
  const linkProps = href.startsWith('/') ? {to: href, href, isInternal: true, ...props} : { href, isInternal: false, ...props }

  return <AnimatedLink {...linkProps} {...props} styles={{
    backgroundImage: theme =>
      `linear-gradient(${theme.colors.text}, ${theme.colors.text}), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
  }} />
}

function Heading({Component, ...props}) {
  const [_, copyToClipboard] = useCopyToClipboard()
  const { href } = useLocation()
  const [isHovering, setIsHovering] = React.useState(false)
  

  return (
    <span
      onClick={() => copyToClipboard(href)}
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        marginTop: '32px',

        ":hover": {
          cursor: 'default'
        }
      }}
    >
      <FaLink sx={{ position: "absolute", left: "-1.5rem", display: isHovering ? "initial" : "none" }}/>
      
      <a href={`#${props.id}`} sx={{ textDecoration: "none", color: "text" }} 
      >
        <Component sx={{ margin: 0 }} {...props} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}/>
      </a>
      
    </span>
  )
}

export const components = {
  h2: props => <Heading Component={'h2'} sx={{fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"]}} {...props}/>,
  h3: props => <Heading Component={'h3'} sx={{fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"],}} {...props}/>,
  h4: props => <Heading Component={'h4'} sx={{fontSize: ["1.125rem", "1.125rem", "1.5rem", "1.5rem"]}} {...props}/>,
  h5: props => <Heading Component={'h5'} sx={{fontSize: ["1.125rem", "1.125rem", "1.125rem", "1.125rem"]}} {...props}/>,
  h6: props => <Heading Component={'h6'} {...props}/>,
  a: CustomLink,
  Emoji,
  pre: props =>
    props.children.props["live"] ? (
      <InteractiveCodeBlock
        code={props.children.props.children.trim()}
        noInline={props.children.props["noInline"]}
        className={
          props.children.props.className ? props.children.props.className : ""
        }
        disabled={props.children.props.disabled}
        title={props.children.props.title}
        metastring={props.children.props.metastring}
      />
    ) : (
      <CodeBlock
        props={props.children.props}
        className={
          props.children.props.className ? props.children.props.className : ""
        }
        code={props.children.props.children.trim()}
        title={props.children.props.title}
        hl={props.children.props.metastring}
        children={props.children.props.children}
        showLines={props.children.props.showLines}
      />
    ),
  inlineCode: props => (
    <code {...props} sx={{ variant: `styles.InlineCode`, color: "accent" }}>
      {props.children}
    </code>
  ),
  img: props => (
    <div sx={{ width: '100%', maxWidth: '100%' }}><img sx={{ width: '100%' }} {...props}/></div>
  )
}
