import React from "react"

export const useActiveId = itemIds => {
  const [activeHeading, setActiveHeading] = React.useState("")

  React.useEffect(() => {
    //   https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveHeading(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -70% 0%` }
    )

    itemIds.forEach(id => {
      observer.observe(document.getElementById(id))
    })

    // clean up
    return () => {
      itemIds.forEach(id => {
        observer.unobserve(document.getElementById(id))
      })

      observer.disconnect()
    }
  }, [itemIds])

  return activeHeading
}
