/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import theme from "../gatsby-plugin-theme-ui/index"

const Pagination = ({ prev, next }) => {
  return (
    <div
      sx={{
        display: "flex",
        marginTop: "2rem",
        alignItems: "center",
        justifyContent: prev && prev.slug ? "space-between" : "flex-end",
      }}
    >
      {prev && prev.slug ? (
        <div>
          <Link to={`${prev.slug}`} sx={{ ...theme.styles.animateUnderline }}>
            <span>← {prev.title}</span>
          </Link>
        </div>
      ) : null}
      {next && next.slug ? (
        <div sx={{ textAlign: "right" }}>
          <Link to={`${next.slug}`} sx={{ ...theme.styles.animateUnderline }}>
            <span>{next.title} →</span>
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default Pagination
