/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useActiveId } from "../hooks/useActiveId"
import { motion } from "framer-motion"

import AnchorLink from "react-anchor-link-smooth-scroll"

import { getHeadings } from "../utils/getHeadings"

const containerVariant = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: isFirstPass => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: isFirstPass * 0.8, // only want the delay on the initial render of the whole ToC
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  }),
}

const childVariant = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
  },
}

const getIds = items => {
  return items.reduce((acc, currItem) => {
    if (currItem.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(currItem.url.slice(1))
    }
    if (currItem.items) {
      acc.push(...getIds(currItem.items))
    }
    return acc
  }, [])
}

// not sure where the bug is
// but there seems to be an issue where if a blog's previous or next article has the same exact
// Table of Contents headings, the intersection observer active heading gets "stuck" as the bottom id
// when you navigate using the previous/next pagination
// changing the headings to not be exactly the same seems to fix the issue
// only problem that still exists seems to be that when using that pagination, the first scroll down skips
// the first heading with the underline animation, but if you scroll back up, it works again ¯\_(ツ)_/¯
const TableOfContents = ({ tableOfContents }) => {
  const idsList = getIds(tableOfContents)
  const activeHeading = useActiveId(idsList)

  const headings = getHeadings(tableOfContents)

  return (
    <aside
      sx={{
        boxSizing: "border-box",
        position: "sticky",
        top: "100px",
        width: "100%",
        bg: "highlight",
        borderRadius: "5px",
        padding: "4px 16px",
      }}
    >
      <h2>Table of Contents</h2>
      <nav>
        <motion.ul
          custom={1}
          variants={containerVariant}
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "8px",
            fontSize: "1.1rem",
            paddingInlineStart: "20px",
          }}
        >
          <NestedItems items={headings} activeId={activeHeading} />
        </motion.ul>
      </nav>
    </aside>
  )
}

function NestedItems({ items, activeId }) {
  return (
    <React.Fragment>
      {items.map(item => (
        <motion.li
          variants={childVariant}
          sx={{
            listStyle: "none",
            marginLeft: item.level * 20,
          }}
          key={item.url}
        >
          <AnchorLink
            href={item.url}
            offset="110"
            sx={{
              variant: `styles.animateUnderline`,
              backgroundImage: theme =>
                `linear-gradient(${theme.colors.highlight}, ${theme.colors.highlight}), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
              backgroundSize:
                activeId === item.url.slice(1)
                  ? "0 2px, 100% 2px"
                  : "100% 2px, 0 2px",

              span: {
                variant: `styles.animateUnderline.span`,
                backgroundPosition:
                  activeId === item.url.slice(1) ? "0" : "100%",
              },
            }}
          >
            <span>{item.title}</span>
          </AnchorLink>
        </motion.li>
      ))}
    </React.Fragment>
  )
}

export default TableOfContents
