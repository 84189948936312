/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import TableOfContents from "../components/table-of-contents"
import Pagination from "../components/pagination"

import { components } from "../mdx-components"

const PageTemplate = ({ data: { mdx }, pageContext: { next, prev } }) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  return (
    <div
      sx={{
        width: "100%",
        position: "relative",
        display: "grid",
        gridTemplateColumns: [
          "90%",
          "90%",
          "1fr minmax(250px, 70ch) minmax(250px, 350px) 1fr",
          "1fr minmax(300px, 70ch) minmax(250px, 350px) 1fr",
        ],
        gridGap: ["0", "0", "32px", "32px"],
        margin: "0 auto",
        justifyContent: "center",
      }}
    >
      {mdx.frontmatter.hero ? (
        <div
          sx={{
            gridColumn: ["1", "1", "2 / -2", "2 / -2"],
            width: "100%",
            maxWidth: "675px",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            justifySelf: "center",
          }}
        >
          <Img
            fluid={mdx.frontmatter.hero.childImageSharp.fluid}
            alt={`${mdx.frontmatter.hero.childImageSharp.fluid.originalName
              .split("-")
              .join(" ")
              .slice(0, -4)}`}
            style={{
              width: "100%",
              // borderRadius: "8px",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
          />
        </div>
      ) : null}
      {mdx.tableOfContents && mdx.tableOfContents.items ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              delay: 1,
            },
          }}
          sx={{
            position: "relative",
            gridColumn: "3",
            width: "100%",
            gridRow: mdx.frontmatter.hero ? "2" : "1",
            display: ["none", "none", "initial", "initial"],
          }}
        >
          <TableOfContents tableOfContents={mdx.tableOfContents.items} />
        </motion.div>
      ) : null}

      <div sx={{ gridColumn: ["1", "1", "2", "2"] }}>
        <article
          sx={{
            position: "relative",
            zIndex: 2,
            gridColumn: ["1", "1", "2", "2"],
            gridRow: "2",
            width: "100%",
            padding: ["0 1rem", "0 1rem", "0", "0"],
          }}
        >
          <MDXProvider
            components={{
              ...components,
              em: props => (
                <em
                  {...props}
                  sx={{
                    variant: `styles.Emphasize`,
                    color: isDark ? "secondary" : "primary",
                  }}
                >
                  {props.children}
                </em>
              ),
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
          <Pagination prev={prev} next={next} />
        </article>
      </div>
    </div>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PageTemplate

export const pageQuery = graphql`
  query DocPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        description
        hero {
          childImageSharp {
            fluid(maxWidth: 675) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`
